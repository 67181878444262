var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content beforetab" }, [
    _c(
      "div",
      { attrs: { id: "layerPop" } },
      [
        _c(
          "win-layer-pop",
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  on: { close: _vm.closePoup },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c("p", { staticClass: "text_desc" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("msg.CART010G010.067")))]),
      _c("br"),
      _c("span", [_vm._v(_vm._s(_vm.$t("msg.CART010G010.070")))]),
    ]),
    _c(
      "p",
      {
        staticStyle: {
          width: "800px",
          "margin-left": "720px",
          "margin-bottom": "10px",
        },
      },
      [
        _c("span", { staticClass: "cal_btn" }, [
          _c(
            "a",
            {
              staticClass: "button sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.exlDownload()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
          ),
        ]),
      ]
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("div", {
        staticStyle: { width: "820px", height: "350px" },
        attrs: { id: "dataRealgrid" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }