<template>
  <!-- 특수화물 선적문의 >> OOG 화물 -->
  <div class="content beforetab">
    <div id="layerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePoup"
        />
      </win-layer-pop>
    </div>
    <p class="text_desc">
      <span>{{ $t('msg.CART010G010.067') }}</span><br />
      <span>{{ $t('msg.CART010G010.070') }}</span>
    </p>
    <p style="width: 800px;margin-left: 720px; margin-bottom: 10px;">
      <span class="cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
    </p>
    <div class="flex_box">
      <!-- <span class="tot_num">Total : <span class="num">{{ total }}</span></span> -->
      <div id="dataRealgrid" style="width: 820px; height: 350px">
      </div>
    </div>
    <!-- content_box // -->
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import Oogs from '@/api/rest/trans/oogs'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import { rootComputed } from '@/store/helpers'
import JSZip from 'jszip'
import moment from 'moment'
import { rfDataGmt } from '@/api/rf-gmt-info'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'astId', dataType: 'text' },
  { fieldName: 'rfDataDtm', dataType: 'text' },
  { fieldName: 'deviTmp', dataType: 'text' },
  { fieldName: 'pctHum', dataType: 'text' },
  { fieldName: 'trtn1', dataType: 'text' },
  { fieldName: 'tset', dataType: 'text' }

]

export const columns = [
  {
    name: 'astId',
    fieldName: 'astId',
    type: 'text',
    header: 'Container No.',
    editable: false
  },
  {
    name: 'rfDataDtm',
    fieldName: 'rfDataDtm',
    type: 'text',
    header: 'Date/Time(UTC)',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
          let data = cell.value
          let str = ''
          data = (data.substring(0, 10) + ' ' + data.substring(11, 16))
          str = '<span>' + data + '</span>'
          return data
      }
    }
  },
  {
    name: 'trtn1',
    fieldName: 'trtn1',
    type: 'text',
    header: 'Current T(°C)',
    editable: false
  },
  {
    name: 'pctHum',
    fieldName: 'pctHum',
    type: 'text',
    header: 'Humidity(%)',
    editable: false
  },
  {
    name: 'tset',
    fieldName: 'tset',
    header: 'Set Point(°C)',
    editable: false
    // numberFormat: '#,##0.##' //소수부는 2자리까지만 입력가능
  },
  {
    name: 'Status',
    fieldName: 'rfDataDtm',
    type: 'text',
    header: 'Status',
    editable: false,
    renderer: 'status_convert'
  }
]

let layout = [
              'astId',
              'rfDataDtm',
              'tset',
              'trtn1',
              'pctHum',
              'Status'
              ]

export default {
  name: 'RfdataGridPop',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')

  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          rfDataGridInfoList: [],
          traceDetail: [],
          inlandList: []
        }
      }
     }
  },
  data: function () {
    return {
      pageCount: 0,
      gridList: [],
      gridFilter: [],
      customComponent: null,

      param: {
        curPage: 1, // 현재 페이지
        pageSize: 15, // 한 페이지당 게시글 수
        bkgShprCstNo: 'KMT001',
        asDtKnd: 'DT',
        asFromDt: ''
      },
      // 상세 관련 DATA
      toggleValue: '',
      btnReqNo: '',
      arrReqNo: [],
      total: 0,
      templateStr: '',

      //그리드 변수
      isPaging1: true,
      currentPage: 0,
      totalPage: 0,
      mUserName: '',
      mNumber: '',

      validationDt: false,
      relYn: true,
      traceDetail: []
    }
  },

  computed: {
    ...rootComputed
  },
  //실시간적으로 변하는 곳에 사용
  watch: {
    parentInfo: {
      handler (val) {
        // console.log('watch parentInfo val @@@ ', val)

        provider.setRows(val.rfDataGridInfoList)
        this.customRender()
      },
      deep: true
    }
  },
  async created () {
    window.rfTh = this

    // GMT 기준 시간 가져오기
    this.ctrInfoList = await rfDataGmt.ctrInfoList

    // console.log('RFDATAGRIDINFO parentInfo @@@@', this.$props.parentInfo)
  },
  mounted: function () {
    console.log('mounted')
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    this.gridDataSet()
    this.customRender()
  },
  methods: {
    gridDataSet () {
      provider = new LocalDataProvider(true)
      gridView = new GridView('dataRealgrid')

      // 그리드 필드 생성
      provider.setFields(fields)
      // columns 명
      gridView.setColumns(columns)
      gridView.setColumnLayout(layout)
      gridView.setDataSource(provider)

      // 헤더 높이 조정
      gridView.header.heights = [35]
      gridView.displayOptions.rowHeight = 30
      gridView.displayOptions.fitStyle = 'even'

      gridView.setStateBar({
        visible: false
      })
      gridView.setRowIndicator({
        visible: false
      })
      gridView.setCheckBar({
        visible: false,
        exclusive: false
      })

      provider.setRows(this.parentInfo.rfDataGridInfoList)

      // gridView.setDisplayOptions({
      //   rowResizable: true,
      //   rowHeight: 34,
      //   fitStyle: 'evenFill'
      // })

      //페이징
      // gridView.setPaging(true, 10)
    },
    customRender: function () {
      const traceDetail = this.parentInfo.traceDetail

      gridView.registerCustomRenderer('status_convert', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')
          span.className = 'custom_render_span'
          parent.appendChild(span)
          parent.appendChild(this._button1 = document.createElement('span'))
          parent.appendChild(this._button2 = document.createElement('span'))
        },
        render: function (grid, model, width, height, info) {
          info = info || {}

          let data = model.value
          data = ((data.substring(0, 10) + data.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

          let arrDtTm = []
          let statusRk = ''
          let status = ''

          let mvntDtTm = ''
          let mvntDt = ''
          let mvntTm = ''
          let date = new Date()
          let utcNow // 현재 시간을 utc로 변환한 밀리세컨드값
          let getTimezoneOffset

          let convCntrDtm = ''
          let polCtrCd = rfTh.$props.parentInfo.polCtrCd
          let podCtrCd = rfTh.$props.parentInfo.podCtrCd
          let polCtrGmt = ''
          let podCtrGmt = ''

          // 날짜 정렬 배열 초기값 세팅
          arrDtTm.push({
              mvntDtTm: data,
              rank: ''
          })

          // RF 날짜 데이터 날짜정렬 배열에 추가
          traceDetail.forEach((element, idx) => {
            // mvntDtTm = element.mvntDt + element.mvntTm
            mvntDt = moment(element.mvntDt).format('YYYY-MM-DD')
            mvntTm = element.mvntTm.substring(0, 2) + ':' + element.mvntTm.substring(2, 4) + ':' + element.mvntTm.substring(4)
            mvntDtTm = mvntDt + ' ' + mvntTm

            date = new Date(mvntDtTm)

            for (let gmtDiff of rfTh.ctrInfoList) {
              if (gmtDiff.keyNm === polCtrCd) {
                polCtrGmt = Number(gmtDiff.info)
              }

              if (gmtDiff.keyNm === podCtrCd) {
                podCtrGmt = Number(gmtDiff.info)
              }
            }

            if (idx === 0 || idx === 1 || idx === 2) {
              getTimezoneOffset = -(polCtrGmt * 60)
            } else {
              getTimezoneOffset = -(podCtrGmt * 60)
            }

            utcNow = date.getTime() + (getTimezoneOffset * 60 * 1000)
            date = new Date(utcNow)

            // 날짜/시간 비교위해 포맷 변환
            convCntrDtm = rfTh.convertDate(date)

            arrDtTm.push({
              mvntDtTm: convCntrDtm,
              rank: element.rk
            })
          })

          // 날짜정렬 배열
          arrDtTm.sort((a, b) => {
            return Number(a.mvntDtTm) - Number(b.mvntDtTm)
          })

          arrDtTm.forEach((element, idx, arr) => {
            if (element.mvntDtTm === data) {
              if (idx !== 0) {
                element.rank = arr[idx - 1].rank
              } else {
                element.rank = 404
              }

              statusRk = Number(element.rank)
            }
          })

          // console.log('arrDtTm @@@@ ', arrDtTm)

          switch (statusRk) {
            case 1:
              status = rfTh.$t('msg.SETT010T010.035') // Container Pick up, msg.SETT010T010.035
              break
            case 2:
              status = rfTh.$t('msg.CART010G010.061') // 선적터미널반입 port of Loading  msg.CSCT060G040.002
              break
            case 3:
              status = rfTh.$t('msg.CART010G010.061') // 선적터미널반입 port of Loading  msg.CSCT060G040.002
              break
            case 5:
              status = rfTh.$t('msg.CART010G010.062') // '선적완료(Onboard)' // On board
              break
            case 8:
              status = rfTh.$t('msg.CART010G010.063') // '양하완료 (도착지)' // Port of Discharging  msg.CSCT060G040.002
              break
            case 10:
              status = rfTh.$t('msg.CART010G010.064') // '양하터미널반출' // Full Gate Out   msg.CART010G010.039
              break
            case 11:
              status = rfTh.$t('msg.CART010G010.065') // '공컨테이너 반납' // Container Return
              break
            case 404:
              status = ''
              break
          }

          var span = this._span
          // text설정.
          span.textContent = status
        }
      })
    },
    openPopup (compNm, kind, reqNo) {
      // console.log('openPopup')
      this.customComponent = compNm

      // this.$ekmtcCommon.layerOpen()
      // 팝업 후처리
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePoup (e) {
      this.customComponent = null

      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.SPEC010G010.009'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    exlDownload () { // 그리드 엑셀 다운로드
      let title = 'RF_CONTAINER_DATA' // 미해결업무_수입_미정산
      const traceDetail = this.parentInfo.traceDetail

      const excelOption = {
        textCallback: function (index, fieldName, value) {
          if (fieldName === 'Status') {
            let data = value
            data = ((data.substring(0, 10) + data.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

            let arrDtTm = []
            let statusRk = ''
            let status = ''

            let mvntDtTm = ''
            let mvntDt = ''
            let mvntTm = ''
            let date = new Date()
            let utcNow // 현재 시간을 utc로 변환한 밀리세컨드값
            let getTimezoneOffset

            let convCntrDtm = ''
            let polCtrCd = rfTh.$props.parentInfo.polCtrCd
            let podCtrCd = rfTh.$props.parentInfo.podCtrCd
            let polCtrGmt = ''
            let podCtrGmt = ''

            // 날짜 정렬 배열 초기값 세팅
            arrDtTm.push({
                mvntDtTm: data,
                rank: ''
            })

            // RF 날짜 데이터 날짜정렬 배열에 추가
            traceDetail.forEach((element, idx) => {
              // mvntDtTm = element.mvntDt + element.mvntTm
              mvntDt = moment(element.mvntDt).format('YYYY-MM-DD')
              mvntTm = element.mvntTm.substring(0, 2) + ':' + element.mvntTm.substring(2, 4) + ':' + element.mvntTm.substring(4)
              mvntDtTm = mvntDt + ' ' + mvntTm

              date = new Date(mvntDtTm)

              for (let gmtDiff of rfTh.ctrInfoList) {
                if (gmtDiff.keyNm === polCtrCd) {
                  polCtrGmt = Number(gmtDiff.info)
                }

                if (gmtDiff.keyNm === podCtrCd) {
                  podCtrGmt = Number(gmtDiff.info)
                }
              }

              if (idx === 0 || idx === 1 || idx === 2) {
                getTimezoneOffset = -(polCtrGmt * 60)
              } else {
                getTimezoneOffset = -(podCtrGmt * 60)
              }

              utcNow = date.getTime() + (getTimezoneOffset * 60 * 1000)
              date = new Date(utcNow)

              // 날짜/시간 비교위해 포맷 변환
              convCntrDtm = rfTh.convertDate(date)

              arrDtTm.push({
                mvntDtTm: convCntrDtm,
                rank: element.rk
              })
            })

            // 날짜정렬 배열
            arrDtTm.sort((a, b) => {
              return Number(a.mvntDtTm) - Number(b.mvntDtTm)
            })

            arrDtTm.forEach((element, idx, arr) => {
              if (element.mvntDtTm === data) {
                if (idx !== 0) {
                  element.rank = arr[idx - 1].rank
                } else {
                  element.rank = 404
                }

                statusRk = Number(element.rank)
              }
            })

            // console.log('arrDtTm @@@@ ', arrDtTm)

            switch (statusRk) {
              case 1:
                status = rfTh.$t('msg.SETT010T010.035') // Container Pick up, msg.SETT010T010.035
                break
              case 2:
                status = rfTh.$t('msg.CART010G010.061') // 선적터미널반입 port of Loading  msg.CSCT060G040.002
                break
              case 3:
                status = rfTh.$t('msg.CART010G010.061') // 선적터미널반입 port of Loading  msg.CSCT060G040.002
                break
              case 5:
                status = rfTh.$t('msg.CART010G010.062') // '선적완료(Onboard)' // On board
                break
              case 8:
                status = rfTh.$t('msg.CART010G010.063') // '양하완료 (도착지)' // Port of Discharging  msg.CSCT060G040.002
                break
              case 10:
                status = rfTh.$t('msg.CART010G010.064') // '양하터미널반출' // Full Gate Out   msg.CART010G010.039
                break
              case 11:
                status = rfTh.$t('msg.CART010G010.065') // '공컨테이너 반납' // Container Return
                break
              case 404:
                status = ''
                break
            }

            return status
          } else {
            return value
          }
          //return 값
        }
      }
      this.$ekmtcCommon.gridExportExcel(gridView, title, excelOption)
    },
    convertDate (date) {
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      var hours = ('0' + date.getHours()).slice(-2)
      var minutes = ('0' + date.getMinutes()).slice(-2)
      var seconds = ('0' + date.getSeconds()).slice(-2)

      var timeString = hours + ':' + minutes + ':' + seconds
      var dateString = year + '-' + month + '-' + day

      return ((dateString + timeString).replaceAll('-', '')).replaceAll(':', '')
    }
  }
}
</script>

 <style scoped>
.rg-table input[type="radio"], .rg-table input[type="checkbox"] {
    height: 16px !important;
}

/*https://docs.realgrid.com/guides/renderer/custom-renderer-img-buttons*/

/* a.no-uline:hover{
  text-decoration:underline !important;
} */

ul.grid{
 position:absolute;
}

span.float {
  float:right;
}
.tbl_search td {
  padding: 4px 10px 0px 10px !important;
}

.text_desc {
    color: #777777;
    font-size: 13px;
}
 </style>
